import React from "react"
import { graphql } from "gatsby"
import Meta from "../components/meta";
import Archive from "../components/archive";

export const Head = () => {
  return (
    <Meta title="ข่าว" />
  )
}

const PostPage = ({ data }: any) => {
  return (
    <Archive posts={data.latest.nodes} type="post" />
  );
}

export const pageQuery = graphql`
  query {
    latest: allStrapiPost(sort: { fields: [publishedAt], order: DESC }) {
      nodes {
        ...PostData
      }
    }
  }
`

export default PostPage